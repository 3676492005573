import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import AboutPanel from "../components/about-panel"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import AppsPanel from "../components/apps-panel"

const EcomPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Headless E-commerce Software Developers" />
        <Hero title="On-Demand Headless E-commerce Software Developers" description="Are you Looking for a world-class eCommerce development team?,  Look no further!, We'll provide you with a team of experts who will work closely with you on your projects. You'll be able to direct them and communicate with them however you want. So why wait? Contact us today and get started on your next eCommerce project!" image="pages/shopify-hero.jpg" />
        <AboutPanel title="I can help you develop your next head-less Shopify e-commerce." description="Hire high-performing, on-demand e-commerce developers" image="pages/shopify-developer.jpg"  />
        <AppsPanel description="Need help building your eCommerce store? Our expert developers will join your team and help you get the job done. You manage the projects, and our world-class, full-time teams of developers are yours to direct." />

      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default EcomPage
