import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

const AppPanel = (props: { description: string }) => {
  return (
    <Grid container spacing={3} style={{ minHeight: 500, marginTop: 40, marginBottom: 40 }} component="section"  justify="center" alignItems="flex-center">

      <Grid
        item={true}
        xs={12}
        sm={8}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >


        <Typography
          variant="h6"
          component="p"
          style={{ marginBottom: 30, marginTop: 20, textAlign: 'center', fontWeight: '100', fontStyle: 'italic' }}
        >

          {props.description}
        </Typography>

      </Grid>

    </Grid>
  )
}
export default AppPanel
